<script>
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/rhvddzym.json";
import Footer from "@/components/landing/Footer";
import AuthService from "@/services/auth-service";
import Swal from "sweetalert2";

export default {
  components: {
    Footer,
    lottie: Lottie
  },
  methods: {
    handleSubmit() {
      if(!this.email) {
        return;
      }
      AuthService.passwordResetToken(this.email).then(() => {
        this.request_completed = true;
      }).catch((e) => {
        console.log(e); //TODO: debug info
        Swal.fire({
          title: this.$t("t-default-error-response-message"),
          icon: 'error',
        });
      });
    },
  },
  data() {
    return {
      request_completed: false,
      email: "",
      defaultOptions: {
        animationData: animationData
      },
    }
  }
}
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logo-light.png" alt="" height="30">
                </router-link>
              </div>
              <!-- <p class="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> -->
            </div>
          </div>
        </div>


        <!-- end row -->
        <div class="row justify-content-center" v-if="request_completed">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4 text-center">
                <div class="avatar-lg mx-auto mt-2">
                  <div class="
                      avatar-title
                      bg-light
                      text-success
                      display-3
                      rounded-circle
                    ">
                    <i class="ri-checkbox-circle-fill"></i>
                  </div>
                </div>
                <div class="mt-4 pt-2">
                  <h4>Check your email !</h4>
                  <p class="text-muted mx-4">
                    We've emailed you instructions to reset your password.
                  </p>
                  <div class="mt-4">
                    <router-link :to="{name: 'login'}" class="btn btn-success w-100">Back to Login
                    </router-link>
                  </div>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->
          </div>
        </div>

        <div class="row justify-content-center" v-if="!request_completed">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">

              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">{{ $t("t-forgot-password") }}</h5>
                  <p class="text-muted"></p>

                  <lottie class="avatar-xl" colors="primary:#0ab39c,secondary:#405189"
                          :options="defaultOptions" :height="120" :width="120" />

                </div>

                <div class="alert alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                  {{ $t("t-forgot-password-tip") }}
                </div>
                <div class="p-2">
                  <form  @submit.prevent="handleSubmit">
                    <div class="mb-4">
                      <label class="form-label">{{ $t("t-profile-email") }}</label>
                      <input v-model="email" type="email" class="form-control" id="email" required
                             :placeholder="$t('t-enter-email')">
                    </div>

                    <div class="text-center mt-4">
                      <button class="btn btn-success w-100" type="submit">{{ $t("t-forgot-password-send-reset-ling") }}</button>
                    </div>
                  </form><!-- end form -->
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div class="mt-4 text-center">
              <p class="mb-0">{{ $t("t-i-remember-password-message") }}
                <router-link  :to="{name:'login'}" class="fw-semibold text-primary text-decoration-underline">
                  {{ $t("t-button-click-here") }}
                </router-link>
              </p>
            </div>

          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <Footer></Footer>
  </div>
  <!-- end auth-page-wrapper -->
</template>